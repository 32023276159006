import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const WonPercentageChart = ({ data }) => {
  return (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip cursor={{ fill: 'none' }} />
          <Legend />
          <Bar dataKey="Short %" fill="rgba(90, 157, 212,.8)" background={{ fill: '#fff' }} />
          <Bar dataKey="Long %" fill="rgba(44, 62, 108, 0.8)" background={{ fill: '#fff' }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WonPercentageChart;
