import React, { useEffect, useState } from "react";
import bellIcon__blue from "../ASSETS/bellBlue.svg";
import NotificationDetail from "../NotificationDetail"
import Notif from "./Notif";
import "../CSS/navbar.css";



export default function AdminNavbar() {

  const [isNotifOpen, setIsNotifOpen] = useState(false);
  const [notifDetailShown, setNotifDetailShown] = useState(false);
  const [notifDataForDetail, setNotifDataForDetail] = useState({});
  const [notif, setNotif] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/inbox.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: "2"
          }),
        });
        const data = await response.json();
        setNotif(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);


  return <>
    <div className="navbar">
      {/* <div className="fakelmao"></div> */}
      {notifDetailShown ? <>
        <NotificationDetail
          id={notifDataForDetail.id}
          notifDetailShown={notifDetailShown}
          setNotifDetailShown={setNotifDetailShown}
          type={notifDataForDetail.type}
          subject={notifDataForDetail.subject}
          invoiceNumber={notifDataForDetail.invoiceNumber}
          email={notifDataForDetail.email}
          message={notifDataForDetail.message}
          dueDate={notifDataForDetail.dueDate}
          amount={notifDataForDetail.amount}
          dateReceived={notifDataForDetail.dateReceived}
          isAccepted={notifDataForDetail.isAccepted}
          setIsAccepted={notifDataForDetail.setIsAccepted}
          from={notifDataForDetail.from}
        ></NotificationDetail>
      </> : <></>}
      <div className="notifContainer" onClick={() => {
        setIsNotifOpen(!isNotifOpen);
      }}>
        <img className="notif ClickHover" src={bellIcon__blue} alt="Notifications"></img>
        {isNotifOpen ? <> <span className="arrowOfPopUp"></span>
          <div className="notif__popUp">

            <div className="notif__popUp__All">
              <div className="notif__popUp__absolute">

                <p className="heading">Notifications</p>

                {notif.map((item) => {
                  return (
                    <div className="notif__popUp__link" onClick={() => {
                      setNotifDetailShown(true);
                      setNotifDataForDetail({ 'from': item[`from`], 'id': item[`id`], 'type': item[`type`], 'subject': item[`subject`], 'invoiceNumber': item['invoice number'], 'email': item['email'], 'message': item['message'], 'dueDate': item['due date'], 'amount': item['amount'], 'dateReceived': item['date received'], 'isread': false, 'isAccepted': item['isAccepted'], 'setIsAccepted': item['setIsAccepted'] });
                    }}>
                      <Notif
                        key={item[`id`]}
                        type={item[`type`]}
                        from={item[`from`]}
                        subject={item[`subject`]}
                        invoiceNumber={item[`invoice number`]}
                        message={item[`message`]}
                        dueDate={item[`due date`]}
                        amount={item[`amount`]}
                        user_name={item[`user_name`]}
                        dateReceived={item[`date received`]}
                      ></Notif></div>
                  );
                }
                )}
              </div>
            </div>



          </div></> : ""}

      </div>

    </div >

  </>
}