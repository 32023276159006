// PDFViewer.jsx
import React, { useState, useEffect } from 'react';

const PDFViewer = ({ pdfUrl }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Simple check to see if the PDF URL is valid
    const checkPDF = async () => {
      try {
        const response = await fetch(pdfUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        setHasError(true);
      }
    };

    checkPDF();
  }, [pdfUrl]);

  if (hasError) {
    return null;
  }

  const iframeStyle = {
    width: '100%',
    height: '100vh',
    border: 'none',
  };

  const containerStyle = {
    backgroundColor: 'white',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={containerStyle}>
      <iframe
        src={`${pdfUrl}#toolbar=0`}
        style={iframeStyle}
        title="PDF Viewer"
      />
    </div>
  );
};

export default PDFViewer;
