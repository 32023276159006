import React from 'react';
import GaugeChart from 'react-gauge-component';

const GaugeComp = ({ value }) => {
  // Define the colors and their corresponding thresholds
  const ranges = [
    { color: 'green', label: 'Risk Free', threshold: 0 },
    { color: 'yellow', label: `${value}% Risk`, threshold: 10 },
    { color: 'orange', label: `${value}% Risk`, threshold: 15 },
    { color: 'red', label: `${value}% Risk`, threshold: 20 }
  ];

  // Get the current label
  const getLabel = (value) => {
    if (value === ranges[0].threshold) return ranges[0].label;
    if (value >= ranges[1].threshold && value < ranges[2].threshold) return ranges[1].label;
    if (value >= ranges[2].threshold && value < ranges[3].threshold) return ranges[2].label;
    if (value >= ranges[3].threshold) return ranges[3].label;
    return ranges[0].label; // Default to 'Risk Free' if value is out of defined ranges
  };

  const label = getLabel(value);

  // Define arcs to properly segment the gauge
  const arcs = {
    cornerRadius: 15,
    padding: 0.01,
    nbSubArcs: 4,
    colorArray: ['#73bd4c', '#f4c74f', '#f1a23a', '#dc463d'],
    gradient: false,
    subArcs: [
      { limit: 0 },   // First arc for value 0 (green)
      { limit: 10 },  // Second arc for values 1 to 10 (yellow)
      { limit: 15 },  // Third arc for values 11 to 15 (orange)
      { limit: 20 }   // Fourth arc for values 16 to 20 (red)
    ]
  };

  return (
    <div className='gaugeChart__InnerContainer' style={{ width: '100%', height: '100%' }}>

      <GaugeChart
        id='gauge-chart1'
        type='semicircle'
        animate={true}
        value={value}
        minValue={0}
        maxValue={20}
        arc={arcs}
        style={{ width: '100%', height: 'auto' }}
      />
      <div className='Gaugelabel' style={{ marginTop: '10px', fontSize: '18px', fontWeight: 'bold', color: "#2c3e6c", textAlign: 'center' }}>
        {label}
      </div>
    </div>
  );
};

export default GaugeComp;
