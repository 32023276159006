import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import AdminNavbar from "./AdminNavbar";
import AAGraph from "./AAGraph";
import BalanceGraph from "./BalanceGraph";
import { useParams, useLocation } from "react-router-dom";
// import ProfitNLoss from "./ProfitNLoss";
import WonPercentageChart from "./WonPercentageChart";
import AAPieChart from "./AAPieChart";
import GaugeComp from './GaugeComp';
import "../CSS/Investor.css";
import EditIcon from "../ASSETS/Edit.svg";
import X from "../ASSETS/X.svg";
import UserProfile from '../Funcs/UserProfile';
import PDFViewer from './PDFViewer';

export default function InvestorPage({ adminSelectedPage, setAdminSelectedPage }) {
  const [notif, setNotif] = useState([]);
  const [notifDetailShown, setNotifDetailShown] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedSection, setSelectedSection] = useState("Positions");
  const today = new Date();
  const [viewedWeek, setViewedWeek] = useState('All Weeks');
  const [viewedMonth, setViewedMonth] = useState(today.toLocaleString('default', { month: 'long' }));
  const [viewedYear, setViewedYear] = useState(today.getFullYear());

  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pool = queryParams.get('pool');

  const [notes, setNotes] = useState([]);







  // UseEffect to handle the Date picker "Apply" button click
  useEffect(() => {
    const monthInput = document.getElementById("monthInput");
    const yearInput = document.getElementById("yearInput");

    if (monthInput && yearInput) {
      // Set default selected month
      // monthInput.value = today.getMonth();

      // // Set default selected year
      // yearInput.value = today.getFullYear();
    }
  }, []);

  // Function to handle the Date picker "Apply" button click
  const handleApplyClick = () => {
    setViewedWeek(defaultWeekValue === "01" ? "Week 1"
      : defaultWeekValue === "02" ? "Week 2"
        : defaultWeekValue === "03" ? "Week 3"
          : defaultWeekValue === "04" ? "Week 4"
            : defaultWeekValue === "00" ? "All Weeks"
              : defaultWeekValue === "All Weeks" ? "All Weeks"
                : "Oops! Something went wrong");
    setViewedMonth(defaultMonthValue === "01" ? "January"
      : defaultMonthValue === "02" ? "February"
        : defaultMonthValue === "03" ? "March"
          : defaultMonthValue === "04" ? "April"
            : defaultMonthValue === "05" ? "May"
              : defaultMonthValue === "06" ? "June"
                : defaultMonthValue === "07" ? "July"
                  : defaultMonthValue === "08" ? "August"
                    : defaultMonthValue === "09" ? "September"
                      : defaultMonthValue === "10" ? "October"
                        : defaultMonthValue === "11" ? "November"
                          : defaultMonthValue === "12" ? "December"
                            : defaultMonthValue === today.toLocaleString('default', { month: 'long' }) ? today.toLocaleString('default', { month: 'long' })
                              : "Oops! Something went wrong");
    setViewedYear(defaultYearValue);

    setDatePickerVisible(false);
  };

  const [defaultWeekValue, setDefaultWeekValue] = useState('00');
  const [defaultMonthValue, setDefaultMonthValue] = useState(today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1);
  const [defaultYearValue, setDefaultYearValue] = useState(today.getFullYear());

  useEffect(() => {
    const monthInput = document.getElementById("monthInput");
    const yearInput = document.getElementById("yearInput");
    if (monthInput && yearInput) {
      // Set default selected month
      monthInput.value = today.getMonth();
      // Set default selected year
      yearInput.value = today.getFullYear();
    }
  }, []);
  const dateToSend = `${defaultWeekValue}-${defaultMonthValue}-${defaultYearValue}`;
  // transaction history variables
  const [walletHistory, setWalletHistory] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/transactions.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: id,
          }),
        });
        const data = await response.json();
        setWalletHistory(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/dashboard.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: id,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id, dateToSend]);

  const defaultData = {
    InvestorDetails: {
      name: '',
      mail: '',
      phone: '',
      starting_balance: '',
      profit_sharing: '',
      start_date: '',
    },
    PersonalEarnings: {
      shared_percentage: '',
      profits: '',
      bonus: '',
      total_balance: '',
      day_of_payout: '',
      status: '',
      note: ''
    },
    PoolDetails: {
      pool_id: '',
      size: '',
      roi: '',
      revenue: ''
    }
  };
  const data = dashboardData ?? defaultData;
  const [positionsData, setPositionsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/posOr.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setPositionsData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [pool, dateToSend]);
  const defaultPositions = {
    "Positions": [
      {
        "time_open": "",
        "symbol": "",
        "type": "",
        "volume": "",
        "price_open": "",
        "sl": "",
        "tp": "",
        "time_close": "",
        "price_close": "",
        "commission": "",
        "swap": "",
        "profit": ""
      }
    ],
    "Orders": [
      {
        "time_open": "",
        "symbol": "",
        "type": "",
        "volume": "",
        "price_open": "",
        "sl": "",
        "tp": "",
        "time_close": "",
        "state": "",
        "comment": ""
      }
    ]
  };
  const posData = positionsData ?? defaultPositions;
  const [resultsData, setResultsData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/getResults.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setResultsData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [pool, dateToSend]);
  const [revenueChart, setRevenueChart] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/revenueChart.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: id,
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setRevenueChart(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id, pool, dateToSend]);

  const wonPercentage = [
    { name: 'Short Trades', 'Short %': parseFloat(resultsData?.short_trades_won_percentage?.replace('%', '') ?? 0) },
    { name: 'Long Trades', 'Long %': parseFloat(resultsData?.long_trades_won_percentage?.replace('%', '') ?? 0) },
  ];

  const [balanceData, setBalanceData] = useState([]);

  useEffect(() => {
    // Fetch balance data from the PHP script
    const fetchData = async () => {
      const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/balanceGraph.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: id,
          date: dateToSend,
        }),
      });
      const data = await response.json();
      setBalanceData(data);
    };

    fetchData();
  }, [id, dateToSend]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.stackinvestment.net/stackInvest-BackEnd/fetchNotes.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pool_id: pool,
            date: dateToSend,
          }),
        });
        const data = await response.json();
        setNotes(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [pool, dateToSend]);
  const pieChartdata = [
    { name: "Pool Size", value: data.PoolDetails?.size ?? 0 },
    { name: "Shared Percentage", value: data.InvestorDetails?.starting_balance ?? 0 }
  ];
  const pdfUrl = `https://admin.stackinvestment.net/PDF/${dateToSend}${pool}.pdf`;
  return (
    <div className="DashHero">
      {datePickerVisible ? <div className="DashHero__blurBg">
        <div className="blurcard">
          <img className="X" src={X} alt="Close Date Picker" onClick={() => { setDatePickerVisible(!datePickerVisible) }} />
          <h1>Date Picker</h1>
          <div className="flex">
            <div className="flex--col">
              <h2>Week</h2>
              <select value={defaultWeekValue} name="Week" id="weekInput" onChange={(e) => {
                setDefaultWeekValue(e.target.value)
              }
              }>

                <option value="00">All Weeks</option>
                <option value="01">Week 1</option>
                <option value="02">Week 2</option>
                <option value="03">Week 3</option>
                <option value="04">Week 4</option>
              </select>
            </div>
            <div className="flex--col">
              <h2>Month</h2>
              {/* <input type="text" placeholder="Month" id="monthInput" /> */}
              <select value={defaultMonthValue} name="Month" id="monthInput" onChange={(e) => {
                setDefaultMonthValue(e.target.value)
              }
              }>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
            <div className="flex--col">
              <h2>Year</h2>
              {/* <input type="text" placeholder="Year" id="yearInput" /> */}
              <select value={defaultYearValue} name="Year" id="yearInput" onChange={(e) => {
                setDefaultYearValue(e.target.value)
              }}
              >
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
              </select>
            </div>
          </div>
          <div className="CTAContainer">
            <button className="CTA" onClick={() => { handleApplyClick() }}>Apply</button>
          </div>
        </div>
      </div>
        : <></>}

      <AdminSidebar adminSelectedPage={adminSelectedPage} setAdminSelectedPage={setAdminSelectedPage}></AdminSidebar>
      <AdminNavbar
        notifDetailShown={notifDetailShown}
        setNotifDetailShown={setNotifDetailShown}
      >
      </AdminNavbar>
      <div className="hero__content">
        <h1 className="content__heading">Dashboard</h1>
        <div className="month">
          <p className="monthValue">{`${viewedWeek}, ${viewedMonth} ${viewedYear}`}</p>
          <img className="editIcon" title="Choose Date" src={EditIcon} alt="Choose Month" onClick={() => {
            setDatePickerVisible(!datePickerVisible);
          }}></img>
        </div>
        {/* Start of AllCards */}
        <div className="allCards">
          {/* Start of card 1 */}
          <div className="card">
            <h2 className="card__heading">Investors Detail's</h2>
            <div className="card__item">
              <p className="card__subTitle">Investor's Name:</p>
              <p className="card__variable">{data.InvestorDetails?.name ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Email:</p>
              <p className="card__variable">{data.InvestorDetails?.mail ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Phone number:</p>
              <p className="card__variable">{data.InvestorDetails?.phone ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Date of Start:</p>
              <p className="card__variable">{data.InvestorDetails?.start_date ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Starting Balance:</p>
              <p className="card__variable">{`$${data.PersonalEarnings?.starting_balance ?? ''}`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Profit Sharing:</p>
              <p className="card__variable">{`${data.PersonalEarnings?.profit_sharing ?? '50'}%`}</p>
            </div>

          </div>
          {/* End of card 1 */}
          {/* ------------------ */}
          {/* Start of card 2 */}
          <div className="card">
            <h2 className="card__heading">Personal Earnings</h2>
            <div className="card__item">
              <p className="card__subTitle">Shared Percentage:</p>
              <p className="card__variable">{`${Math.round(data.PersonalEarnings?.shared_percentage * 10) / 10}%`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Day of Payout:</p>
              <p className="card__variable">{data.PersonalEarnings?.day_of_payout ?? ''}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Profit Amount:</p>
              <p className="card__variable">{`$${Math.round(data.PersonalEarnings?.profits * 10) / 10 ?? ''}`}</p>
            </div>
            <div className="card__item">
                  <p className="card__subTitle">Total balance from Date of Start:</p>
                  <p className="card__variable">{`$${data.PersonalEarnings?.total_balance ?? ''}`}</p>
                </div>
                <div className="card__item">
                  <p className="card__subTitle">Total Profit from Date of Start:</p>
                  <p className="card__variable">{`$${(data.PersonalEarnings?.total_balance ?? 0) - (data.PersonalEarnings?.starting_balance ?? 0)}`}</p>
                </div>
          </div>
          {/* End of card 2 */}
          {/* ------------------ */}
          {/* Start of card 3 */}
          <div className="card">
            <h2 className="card__heading">Pool Details</h2>
            <div className="card__item">
              <p className="card__subTitle">Pool Size:</p>
              <p className="card__variable">{`$${data.PoolDetails?.size ?? ''}`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">Revenue Earned:</p>
              <p className="card__variable">{`$${Math.round(data.PoolDetails?.revenue * 10) / 10 ?? ''}`}</p>
            </div>
            <div className="card__item">
              <p className="card__subTitle">ROI:</p>
              <p className="card__variable">{`${Math.round(data.PoolDetails?.roi * 10) / 10}%`}</p>
            </div>
          </div>
          {/* End of card 3 */}
          {/* ------------------ */}
          {/* Start of card 4 */}
          <div className="card">
            <h2 className="card__heading">Notes</h2>
            <div className="card__item card__item--input">
              <ul>
                {notes.length === 1 ? <p className="card__variable notes-items">
                  {notes.map((item, index) => {
                    return <p className="notes-items" key={index}>{item[`Note`]}</p>
                  })}
                </p> : notes.length > 1 ?

                  notes.map((item, index) => {
                    return <li className="notes-items notes-items--padded" key={index}>{item[`Note`]}</li>
                  })
                  : <p className="notes-items">Notes received from the Admin are shown here...</p>}
              </ul>
            </div>
          </div>
          {/* End of card 4 */}

          {/* End of cardContainer 3 n 4 */}
          {/* ------------------ */}
          {/* Start of card 6 */}
          <div className="card card--PosOrder">
            <div className="header">
              <h1 className={selectedSection === "Positions" ? 'content__heading-One selectedSection' : 'content__heading-One'} onClick={() => { setSelectedSection("Positions") }}>Positions</h1>
              <h1 className={selectedSection === "Order" ? 'content__heading-Two selectedSection' : 'content__heading-Two'} onClick={() => { setSelectedSection("Order") }}>Order</h1>
            </div>
            {
              selectedSection === "Positions" ? <div className="shadowOfTable">
                <div className="row row-subTitle">
                  <p className="card__subTitle row--flexBaseSupaBig">Open Time</p>
                  <p className="card__subTitle row--flexBaseBig">Symbol</p>
                  <p className="card__subTitle row--flexBaseSmall">Type</p>
                  <p className="card__subTitle row--flexBaseBig">Volume</p>
                  <p className="card__subTitle row--flexBaseBig">Open Price</p>
                  <p className="card__subTitle row--flexBaseSmall">S/L</p>
                  <p className="card__subTitle row--flexBaseSmall">T/P</p>
                  <p className="card__subTitle row--flexBaseSupaBig">Close Time</p>
                  <p className="card__subTitle row--flexBaseBig">Close Price</p>
                  <p className="card__subTitle row--flexBaseBig">Profit</p>
                </div>
                <div className="card__item card__item--historyTable">
                  {/* --- */}

                  {posData.Positions.map((item) => (
                    <div className="row row--PosOrder" key={item.id}>
                      <p className="card__variable row--flexBaseSupaBig row--wrap">{item[`time_open`]}</p>
                      <p className="card__variable row--flexBaseBig">{item.symbol}</p>
                      <p className="card__variable row--flexBaseSmall">{item.type}</p>
                      <p className="card__variable row--flexBaseBig">{item.volume}</p>
                      <p className="card__variable row--flexBaseBig">{item[`price_open`]}</p>
                      <p className="card__variable row--flexBaseSmall">{item[`sl`]}</p>
                      <p className="card__variable row--flexBaseSmall">{item[`tp`]}</p>
                      <p className="card__variable row--flexBaseSupaBig">{item[`time_close`]}</p>
                      <p className="card__variable row--flexBaseBig">{item[`price_close`]}</p>
                      <p className="card__variable row--flexBaseBig">{item[`profit`]}</p>
                    </div>
                  ))}
                  {/* --- */}
                </div>
              </div> : selectedSection === "Order" ? <div className="shadowOfTable">
                <div className="row row-subTitle">
                  <p className="card__subTitle row--flexBaseSupaBig">Open Time</p>
                  <p className="card__subTitle row--flexBaseBig">Symbol</p>
                  <p className="card__subTitle row--flexBaseSmall">Type</p>
                  <p className="card__subTitle row--flexBaseBig">Volume</p>
                  <p className="card__subTitle row--flexBaseBig">Open Price</p>
                  <p className="card__subTitle row--flexBaseSmall">S/L</p>
                  <p className="card__subTitle row--flexBaseSmall">T/P</p>
                  <p className="card__subTitle row--flexBaseSupaBig">Close Time</p>
                  <p className="card__subTitle row--flexBaseBig">State</p>
                  <p className="card__subTitle row--flexBaseBig">Comment</p>
                </div>
                <div className="card__item card__item--historyTable">
                  {/* --- */}

                  {posData.Orders.map((item) => (
                    <div className="row row--PosOrder" key={item.id}>
                      <p className="card__variable row--flexBaseSupaBig row--wrap">{item[`time_open`]}</p>
                      <p className="card__variable row--flexBaseBig">{item.symbol}</p>
                      <p className="card__variable row--flexBaseSmall">{item.type}</p>
                      <p className="card__variable row--flexBaseBig">{item.volume}</p>
                      <p className="card__variable row--flexBaseBig">{item[`price_open`]}</p>
                      <p className="card__variable row--flexBaseSmall">{item[`sl`]}</p>
                      <p className="card__variable row--flexBaseSmall">{item[`tp`]}</p>
                      <p className="card__variable row--flexBaseSupaBig">{item[`time_close`]}</p>
                      <p className="card__variable row--flexBaseBig">{item[`state`]}</p>
                      <p className="card__variable row--flexBaseBig">{item[`comment`]}</p>
                    </div>
                  ))}
                  {/* --- */}
                </div>
              </div> : <h1> Can't display content - Error in "selectedSection" </h1>
            }

          </div>
          {/* End of card 6 */}
          {/* ------------------ */}
          {/* Start of card 7 */}
          <div className="card card--Results card--PosOrder">
            <h1 className="card__heading">Results</h1>
            {
              <div className="shadowOfTable shadowOfTable--Results">
                <div className="row row-subTitle">
                  <p className="card__subTitle row--flexBaseSupaBig">
                    Title
                  </p>
                  <p className="card__subTitle row--flexBaseSupaBig">
                    Value
                  </p>
                </div>
                <div className="card__item card__item--historyTable">

                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Total Net Profit:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`total_net_profit`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Short Trades Won %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`short_trades_won_percentage`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Short Trades Won:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`short_trades_won_value`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Long Trades Won %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`long_trades_won_percentage`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Long Trades Won:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`long_trades_won_value`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Total Trades:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`total_trades`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Profit Trades %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`profit_trades_percentage`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Profit Trades:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`profit_trades_value`]}
                    </p>
                  </div>
                  {/* --- */}
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Loss Trades %:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`loss_trades_percentage`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Loss Trades:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`loss_trades_value`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Larget Profit Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`largest_profit_trade`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Largest Loss Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`largest_loss_trade`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Average Profit Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`average_profit_trade`]}
                    </p>
                  </div>
                  <div className="row row--Results" >
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      Average Loss Trade:
                    </p>
                    <p className="card__variable row--flexBaseSupaBig"
                    >
                      {resultsData[`average_loss_trade`]}
                    </p>
                  </div>




                  {/* --- */}
                </div>
              </div>
              // : <h1> Can't display content - Error in "selectedSection" </h1>
            }

          </div>
          {/* End of card 7 */}
          {/* ------------------ */}
        </div>
        {/* End of AllCards */}
      </div>
      {/* end of hero__content */}
      {/* ================= */}
      <div className="TwoChart">
        <div className="PieChart">
          <h1 className="Chart__heading">Shared Percentage</h1>
          <AAPieChart data={pieChartdata} />
        </div>
        {/* ------ */}
        <div className="GaugeChart">
          <h1 className="Chart__heading">Account Status</h1>
          <GaugeComp value={data.PersonalEarnings?.status ?? ''}></GaugeComp>
        </div>
      </div>
      {/* ================ */}
      <div className="RevenueGraph">
        <h1 className="content__heading">Revenue</h1>
        <AAGraph data={revenueChart}></AAGraph>
      </div>
      {/* ================= */}
      <div className="BalanceGraph">
        <h1 className="content__heading">Balance</h1>
        <BalanceGraph data={balanceData} />
      </div>
      {/* ================ */}
      {/*  <div className="ProfitNLoss">
        <h1 className="content__heading">Profit & Loss Analysis</h1>
        <ProfitNLoss></ProfitNLoss>
      </div> */}
      {/* ================ */}
      <div className="ProfitNLoss PercentageWon">
        <h1 className="content__heading">Percentage Won</h1>
        <WonPercentageChart data={wonPercentage} />
      </div>
      {/* ================ */}
      <div className="TransactionSection">

        <h1 className="content__heading Basic-Fade">Account Statement</h1>

        <div className="DipWith__Table">
          {walletHistory.map((item, index) => {
            return (
              <div
                className="row Fly-Up"
                key={item[`id`]}
                style={{
                  animationDuration: `${(index * .075) + .8}s`
                }}
              >
                {/* -------- */}
                {item[`type`] === "Deposit" ? <>
                  <p className="row__name">Deposited</p>
                  <p
                    className="amount row__amount"
                    style={{ color: "#3ec143" }}>
                    +$ {item[`amount`]}
                  </p>
                  {/* ------------------- */}
                </> : item[`type`] === "Profit" ? <>
                  <p className="row__name">Profit</p>
                  <p
                    className="amount row__amount"
                    style={{ color: "#3ec143" }}>
                    +$ {item[`amount`]}
                  </p>
                  {/* ------------------- */}
                </> : item[`type`] === "Withdraw" ? <>
                  <p className="row__name">Withdrawal</p>
                  <p
                    className="amount row__amount"
                    style={{ color: "#dc463d" }}>
                    -$ {item[`amount`]}
                  </p>
                </> : <p>Error404</p>
                }
                {item[`status`] === "Pending" ?
                  <p className="status row__status" style={{ color: "#f1a23a" }}>{item[`status`]}</p>
                  : item[`status`] === "Accepted" ?
                    <p className="status row__status" style={{ color: `#3ec143` }}>{item[`status`]}</p>
                    : item[`status`] === "Declined" ?
                      <p className="status row__status" style={{ color: `#dc463d` }}>{item[`status`]}</p> : <p style={{ textWrap: 'wrap', textAlign: 'center' }}>Oops..Could'nt load status</p>
                }
                <p className="Date row__date">{item[`date`]}</p>
              </div>
            );
          }
          )}
        </div>
      </div>
      <div className="ProfitNLoss PercentageWon">
        <h1 className="content__heading">Trade Report</h1>
        <PDFViewer pdfUrl={pdfUrl} />
      </div>
    </div >
  );
}
