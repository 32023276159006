import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const BalanceGraph = ({ data }) => (
  <div style={{ width: '100%', height: '100vh' }}>
    <ResponsiveContainer width="100%" height="60%">
      <LineChart data={data}>
        <Line type="monotone" dataKey="Balance" stroke="#5a9dd4" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default BalanceGraph;
